<template>
  <q-card flat class="lastOrderedInfo">
    <q-card-section>
      <div class="row items-center">
        <div class="col-12 col-md-12 text-center">
          <div class="text-caption q-mb-sm"
            >You last purchased this product</div
          >
          <div class="text-body2 text-weight-medium text-dark q-mb-sm">Last Purchased:
            {{ lastPurchasedDetail?.lastPurchased?.lastPurchase | formatDate }}
            <br />
            {{ setVariant }}
          </div>
        </div>
        <div
          class="col-12 col-md-12 text-center"
        >
          <q-btn
            unelevated
            no-caps
            color="tertiary"
            class="q-mt-sm"
            size="13px"
            :to="{
              name: 'OrderDetail',
              params: { orderId: lastPurchasedDetail?.lastPurchased?.orderID },
            }"
          >
            View Order
          </q-btn>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LastOrderedInfo',
  props: {
    lastPurchasedDetail: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
  },
  computed: {
    ...mapGetters('product', ['getCurrentSelectedProductVariant']),
    setVariant() {
      let obj = this.getCurrentSelectedProductVariant

      let arr = ''

      for (
        let i = 0;
        i < Object.keys(this.getCurrentSelectedProductVariant).length;
        i++
      ) {
        // Get the key and value of the old object
        let name = Object.keys(obj)[i]
        let value = obj[name]

        arr +=
          arr.length > 0
            ? `${name.charAt(0).toUpperCase() + name.slice(1)}` +
              `: ` +
              `${value}` +
              ', '
            : (arr +=
                `${name.charAt(0).toUpperCase() + name.slice(1)}` +
                `: ` +
                `${value}, `)
      }

      arr = arr.substring(0, arr.length - 2)

      return arr.trim()
    },
  },
}
</script>
<style lang="scss">
.lastOrderedInfo {
  border: 1px solid #ffbf87;
  background: #ffefe1;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  margin-bottom: 20px;
}
</style>
